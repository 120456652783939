.container {
  padding: 20px;
  max-width: 1420px;
  margin: 0 auto;
}

.table-wrapper {
  overflow-x: scroll;
}

.table {
  border: 1px solid #eee;
  table-layout: fixed;
  width: 100%;
  min-width: 2000px;
  margin-bottom: 20px;

  th {
    font-weight: bold;
    padding: 5px;
    background: #efefef;
    border: 1px solid #dddddd;
  }

  td {
    padding: 5px 10px;
    border: 1px solid #eee;
    text-align: left;
  }

  tbody td:hover {
    border: 1px solid black;
  }

  tbody tr:hover {
    background: black;
  }

  tbody tr:nth-child(odd) {
    background: #fff;
  }

  tbody tr:nth-child(even) {
    background: #f7f7f7;
  }
}
